<template>
  <dog-dialog
    ref="dogDialog"
    width="800px"
    :title="isEdit ? '编辑产品' : '新增产品'"
    @loading-confirm="confirm"
    @closed="closed"
  >
    <dog-form
      ref="dogForm"
      :form-data="formData"
      :form-options="formOptions"
      :column-num="2"
      :row-rule="(row) => row.row || 12"
      :col-rule="(row) => row.col || 12"
    ></dog-form>
  </dog-dialog>
</template>

<script>
  import productService from '@/api/service/product.js';
  import batchImgUpload from '@/components/batchImgUpload.vue';

  export default {
    name: 'addProductDialog',
    data() {
      return {
        isEdit: false,
        formData: {},
        formOptions: [
          {
            type: 'input',
            formItem: {
              label: '标题',
              prop: 'title',
              rules: [this.$formRules.required('请输入标题')]
            },
            attrs: {
              'allow-space': true
            },
            row: 1,
            col: 24
          },
          {
            type: 'custom',
            formItem: {
              prop: 'mainImg',
              label: '主图',
              rules: [this.$formRules.required('请上传主图')]
            },
            component: batchImgUpload,
            row: 2,
            col: 24
          },
          {
            type: 'custom',
            formItem: {
              prop: 'detailImg',
              label: '详情图'
            },
            component: batchImgUpload,
            row: 3,
            col: 24
          },
          {
            type: 'input',
            formItem: {
              label: '联系人',
              prop: 'contact'
            },
            row: 4
          },
          {
            type: 'input',
            formItem: {
              label: '联系方式',
              prop: 'phone'
            },
            row: 4
          }
          // {
          //   type: 'switch',
          //   formItem: {
          //     label: '是否启用',
          //     prop: 'enable',
          //     rules: []
          //   },
          //   attrs: {
          //     default: false
          //   },
          //   row: 5
          // },
          // {
          //   type: 'switch',
          //   formItem: {
          //     label: '是否推荐',
          //     prop: 'recommend',
          //     rules: []
          //   },
          //   attrs: {
          //     default: false
          //   },
          //   row: 5
          // }
        ],
        companyId: null
      };
    },
    methods: {
      openFrame(companyId, id) {
        this.companyId = companyId;
        this.$refs.dogDialog.open();
        if (id) {
          this.isEdit = true;
          productService.getProductDetail({ id }).then((res) => {
            this.formData = {
              ...res,
              enable: this.$methods.stringNumToBoolean(res.enable),
              recommend: this.$methods.stringNumToBoolean(res.recommend)
            };
          });
        }
      },
      confirm(done) {
        this.$refs.dogForm
          .validate()
          .then(() => {
            return productService[this.isEdit ? 'editProduct' : 'addProduct']({
              ...this.formData,
              companyId: this.companyId,
              enable: this.$methods.booleanToStringNum(this.formData.enable),
              recommend: this.$methods.booleanToStringNum(
                this.formData.recommend
              )
            });
          })
          .then(() => {
            this.$message.success('保存成功！');
            done();
            this.$emit('confirm');
            this.close();
          })
          .catch(done);
      },
      close() {
        this.$refs.dogDialog.close();
      },
      closed() {
        this.isEdit = false;
        this.formData = {};
        this.companyId = null;
      }
    }
  };
</script>

<style lang="scss" scoped></style>
