<template>
  <div class="flex_column productManage">
    <dog-search
      ref="search"
      :config="searchConfig"
      @search="search"
      :query="query"
    ></dog-search>
    <dog-table
      ref="dogTable"
      id="75f3a65f-7473-40b7-a410-18023913f43d"
      :columns="tableColumns"
      :service="getList"
      column-type="selection"
      @selection-change="selectedData = $event"
    >
      <permission-button slot="btn" :config="btnConfig"> </permission-button>
    </dog-table>
    <add-product-dialog
      ref="addProductDialog"
      @confirm="searchData"
    ></add-product-dialog>
    <set-class-dialog
      ref="setClassDialog"
      @confirm="searchData"
    ></set-class-dialog>
    <set-model-dialog
      ref="setModelDialog"
      @confirm="searchData"
    ></set-model-dialog>
    <batch-operate-dialog
      ref="batchOperateDialog"
      @confirm="searchData"
    ></batch-operate-dialog>
  </div>
</template>

<script>
  import companyService from '@/api/service/company';
  import productService from '@/api/service/product';
  import addProductDialog from './addProductDialog.vue';
  import setClassDialog from './setClassDialog';
  import setModelDialog from './setModelDialog';
  import batchOperateDialog from './batchOperateDialog';
  import {
    getLocalStorageItem,
    setLocalStorageItem
  } from '@/utils/methods/common';

  export default {
    name: 'productManage',
    components: {
      addProductDialog,
      setClassDialog,
      setModelDialog,
      batchOperateDialog
    },
    data() {
      return {
        query: {},
        searchConfig: [
          {
            type: 'select',
            label: '所属公司',
            key: 'companyId',
            attrs: {
              service: this.getCompanyList,
              clearable: false,
              'select-first': true
            }
          },
          {
            type: 'input',
            label: '标题',
            key: 'title'
          },
          {
            type: 'select',
            label: '是否启用',
            key: 'enable',
            attrs: {
              service: this.$enumService('enable')
            }
          },
          {
            type: 'select',
            label: '是否推荐',
            key: 'recommend',
            attrs: {
              service: this.$enumService('recommend')
            }
          }
        ],
        tableColumns: [
          {
            prop: 'operate',
            label: '操作',
            width: 210,
            widthGrow: false,
            render: (h, { props: { row } }) => {
              return (
                <permission-element
                  config={[
                    {
                      code: 'editProduct',
                      callback: () =>
                        this.$refs.addProductDialog.openFrame(
                          this.query.companyId,
                          row.id
                        )
                    },
                    {
                      code: 'setModel',
                      callback: () =>
                        this.$refs.setModelDialog.openFrame(row.id)
                    },
                    {
                      code: 'setClass',
                      callback: () =>
                        this.$refs.setClassDialog.openFrame(
                          this.query.companyId,
                          row.id
                        )
                    }
                  ]}></permission-element>
              );
            }
          },
          { prop: 'title', label: '标题', width: 200 },
          {
            prop: 'mainImg',
            label: '主图',
            width: 200,
            render: (h, { props: { cur } }) =>
              cur &&
              cur.map((item) => (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={item}
                  fit='contain'
                  preview-src-list={cur}
                />
              ))
          },
          {
            prop: 'detailImg',
            label: '详情图',
            width: 200,
            render: (h, { props: { cur } }) =>
              cur &&
              cur.map((item) => (
                <el-image
                  style='height: 30px; margin-right: 10px;'
                  src={item}
                  fit='contain'
                  preview-src-list={cur}
                />
              ))
          },
          { prop: 'price', label: '价格', width: 100 },
          { prop: 'oldPrice', label: '原价', width: 100 },
          {
            prop: 'enable_cn',
            label: '启用',
            width: 70,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.enable_cn}
                onInput={(val) =>
                  this.quickOperate(row.id, 'enable', val)
                }></dog-switch>
            )
          },
          {
            prop: 'recommend_cn',
            label: '推荐',
            width: 70,
            widthGrow: false,
            render: (h, { props: { row } }) => (
              <dog-switch
                value={row.recommend_cn}
                onInput={(val) =>
                  this.quickOperate(row.id, 'recommend', val)
                }></dog-switch>
            )
          },
          { prop: 'contact', label: '联系人', width: 100 },
          { prop: 'phone', label: '联系方式', width: 100 }
        ],
        btnConfig: [
          {
            buttonType: 'primary',
            code: 'addProduct',
            callback: this.addProduct
          },
          {
            buttonType: 'danger',
            code: 'deleteProduct',
            callback: this.deleteProduct
          },
          {
            buttonType: 'primary',
            code: 'batchOperate',
            callback: this.batchOperate
          }
          // {
          //   buttonType: 'primary',
          //   name: '同步价格',
          //   callback: this.syncPrice
          // }
        ],
        selectedData: []
      };
    },
    mounted() {},
    activated() {
      if (this.query.companyId) {
        const productId = getLocalStorageItem('productQuery');
        if (productId) {
          this.searchData();
        }
      }
    },
    methods: {
      getCompanyList() {
        return companyService.getOwnCompanyEnum().then((res) => {
          if (!res[0]) {
            this.$message.error('请先维护公司信息！');
            return res;
          }
          this.query.companyId = res[0].id;
          this.searchData();
          return res;
        });
      },
      searchData() {
        this.$refs.search.search();
      },
      search(query) {
        const productId = getLocalStorageItem('productQuery');
        if (productId) {
          query.id = productId;
          setLocalStorageItem('productQuery', null);
        }
        this.$refs.dogTable.search(query);
      },
      getList(params) {
        return productService.getProductList(params);
      },
      quickOperate(id, field, val) {
        const transVal = val ? '1' : '0';
        const text = {
          enable: ['禁用', '启用'],
          recommend: ['不推荐', '推荐']
        };
        this.$confirm(`是否确定${text[field][transVal]}所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          productService
            .quickOperateProduct({ id, [field]: transVal })
            .then(this.searchData);
        });
      },
      deleteProduct() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$confirm(`是否确定删除所选数据？`, '提示', {
          type: 'warning'
        }).then(() => {
          productService
            .deleteProducts({ ids: this.selectedData.map((item) => item.id) })
            .then(this.searchData);
        });
      },
      batchOperate() {
        if (this.selectedData.length === 0) {
          return this.$message.error('请至少选择一条数据！');
        }
        this.$refs.batchOperateDialog.openFrame({
          productIds: this.selectedData.map((item) => item.id),
          companyId: this.query.companyId
        });
      },
      addProduct() {
        if (!this.query.companyId) {
          this.$message.error('请先维护公司信息！');
          return;
        }
        this.$refs.addProductDialog.openFrame(this.query.companyId);
      },
      syncPrice() {
        if (!this.query.companyId) {
          this.$message.error('请先维护公司信息！');
          return;
        }
        productService
          .syncProductModel({ companyId: this.query.companyId })
          .then(this.searchData);
      }
    }
  };
</script>

<style lang="scss" scoped></style>
